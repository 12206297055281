.client-row {
  background-color: var(--sesGray);
  color: #fff;
  cursor: pointer;
  padding: 5px 8px;
  margin: 2px 5px;
  border-radius: 10px;
}

.client-row-selected {
  background-color: var(--sesBlue);
}
