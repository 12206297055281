@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --sesBlue: #2a2f99;
  --sesGray: #565656;
  --sesInputBG: #f9f9f9;
  --textGray: #6b6b6b;
  --lightGray: #d6d6d6;
  --sesRed: #d9534f;
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

html {
  font-family: "Open Sans", sans-serif;
}

body {
  font-family: "Open Sans", sans-serif;
}

.App {
  text-align: center;
}

.sideBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 13rem;
  height: 100vh;
  background: #ffffff;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.25);
  z-index: 100;
}

.menuTop {
  height: 50vh;
}

.cssLoginIzq {
  background: transparent url(css/img/bgLogin.png) no-repeat center / cover;
  position: fixed;
  width: 50%;
  height: 100vh;
  overflow: hidden;
  left: 0;
  top: 0;
}

.cssLoginDer {
  background: #ffffff no-repeat center / cover;
  box-shadow: -5px -5px 10px rgba(150, 150, 150, 0.2);
  position: fixed;
  width: 50%;
  height: 100vh;
  overflow: hidden;
  top: 0;
  right: 0;
  overflow-y: auto;
}

.cssInput {
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  border-radius: 10px !important;
  background-color: #f9f9f9 !important;
  border: none;
}

.cssInput:focus {
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
  border-radius: 10px !important;
  background-color: #f9f9f9 !important;
  border: none;
}

.iconInput {
  border-right: solid 2px #6b6b6b;
  padding: 0 0.5rem;
  height: 25px !important;
  margin: -0.75rem 0.25rem;
  position: relative;
  top: 35px;
}

.cssLink {
  text-decoration: none;
  cursor: pointer;
}

.topBar {
  z-index: 99;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
}

.cssUserName {
  border-bottom: solid 1px #565656;
  margin-right: 0.5rem;
  margin-bottom: 0;
  padding-bottom: 0.15rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.cssUserRole {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
  width: 100%;
}
.MuiTableCell-head {
  color: white !important;
}
.menuItem {
  display: flex;
  align-items: center;
  transition: 0.5s;
}
.menuItem:hover {
  background-color: #2a2f99 !important;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  transition: 0.5s;
}
.menuItem:hover svg {
  stroke: white !important;
  transition: 0.5s;
}
.menuItem:hover span {
  color: white !important;
  transition: 0.5s;
}

.itemSubmenu {
  color: #2a2f99 !important;
  justify-content: flex-start !important;
}
.tabsDetalleC {
  min-width: initial !important;
  max-width: initial !important;
  width: 2.2rem !important;
  padding: 0rem 0.1rem !important;
}
.titulosDetalle {
  font-size: 1.2rem;
  font-weight: 600;
  color: #2a2f99 !important;
  text-transform: uppercase;
}

.container {
  max-width: 100% !important;
  padding-left: 15rem !important;
  padding-right: 2rem !important;
}
.pageTitleContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3%;
}
.pageTitle {
  text-align: center;
  padding-left: 13rem;
  width: fit-content;
  font-size: 1.6rem;
  font-weight: 700;
  color: #2a2f99;
  text-transform: uppercase;
}
.foliosContainer {
  min-width: fit-content !important;
  flex-direction: row !important;
  color: #2a2f99 !important ;
  opacity: 1 !important;
  border-radius: 5px !important;
  padding: 0.5%;
  font-size: 1.6rem;
  font-weight: 500;

  box-shadow: 3px 3px 1px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
}
svg.m-auto.action-table {
  cursor: pointer !important;
  margin: 0rem 0.3rem !important;
}

.oneColForm {
  width: 100%;
  margin-right: 1rem;
}

.secondaryButton {
  background: #ffffff !important;
  border-radius: 10px !important;
  color: #2a2f99 !important;
  box-sizing: border-box;
  border: solid 2px;
  max-height: 56px;
}

.secondaryButton:hover {
  font-weight: 500;
}

.gridInvoiceAnticipate {
  grid-template-columns: 40% 60%;
}

.gridInvoiceAnticipateButton {
  grid-template-columns: 20% 20% 31% 29%;
}

.radioButtonsInvoice span {
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px !important;
}

.personInsert .MuiButtonBase-root {
  background-color: #999cd1 !important;
  color: #ffffff !important;
  border-bottom: solid 1px #ffffff !important;
}

.personInsert .MuiButtonBase-root.Mui-expanded {
  background-color: #2a2f99 !important;
}

.containerTableMid {
  /* width: 47% !important;
  margin: 20px auto !important;
  float: right !important;
  position: absolute !important;
  right: 20px !important;
  top: 120px !important; */
}

.tableHeaderPersonal .rdt_TableCol_Sortable div {
  white-space: normal !important;
  text-align: center;
}

.activePerson {
  width: 10px;
  height: 10px;
  background-color: #0cbe13;
  border-radius: 50px;
  float: left;
  position: relative;
  margin: 4px;
}

.inactivePerson {
  width: 10px;
  height: 10px;
  background-color: #c82b2b;
  border-radius: 50px;
  float: left;
  position: relative;
  margin: 4px;
}

.uploadProfilePicture {
  background: #fdfdfd;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
}

.inputFile {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  height: 0px;
  width: 0px;
  display: none;
  /*height: 225px;
  width: 100%;*/
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.containerTableMidEdit {
  width: 47% !important;
  margin: 20px auto !important;
  float: right !important;
  position: absolute !important;
  right: 20px !important;
  top: 30px !important;
}

.sesStrongText {
  margin-left: "1rem";
  font-weight: 600;
  color: var(--sesBlue);
}

.payment-progress > div > div {
  background-color: #0cbe13 !important;
}

.payment-progress span {
  font-size: 10px !important;
}

.fileLinkModal {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  opacity: 0;
  cursor: pointer !important;
  position: absolute;
  z-index: 9;
  height: 25px;
  left: 1.625rem;
}

.textFileModal {
  position: absolute;
  /*width: 80%;
  left: 2rem;
  cursor: pointer;*/
}

.borderReadData {
  border-bottom: solid 2px #b0b0b0;
  border-radius: 2px;
}

.grayCircle {
  border-radius: 50px;
  background-color: #b0b0b0;
  width: 14px;
  height: 14px;
  position: absolute;
  left: -7px;
  bottom: -7px;
}

.personIncidencesFilters {
  grid-template-columns: 50% 25% 25%;
}

.gridEmergencyContact {
  grid-template-columns: 40% 25% 25% 10%;
}

.gridBeneficiary {
  grid-template-columns: 20% 20% 33% 17% 10%;
}

.css-a0lgf4-MuiGrid-root {
  max-width: 50% !important;
}

[role~="columnheader"] > div {
  white-space: break-spaces !important;
}

.btn-close {
  background-image: url("css/img/close.svg") !important;
}
