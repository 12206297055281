.ses-client-accounts-table {
  width: 100%;
}
.ses-client-accounts-table th {
  color: var(--sesBlue);
  text-align: center;
  font-weight: normal;
}

.ses-client-accounts-table td {
  padding: 0 5px;
}
