.dgButton {
  border-radius: 10px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  color: #ffffff;
}

.dgButton:disabled {
  background-color: #d6d6d6;
  cursor: not-allowed;
}

.dgButton.primary {
  background-color: var(--sesBlue);
}

.dgButton.primary:hover {
  background-color: #3f45b7;
}

.dgButton.secondary {
  background-color: var(--sesGray);
}

.dgButton.secondary:hover {
  background-color: #d6d6d6;
}

.dgButton:disabled {
  opacity: 0.5;
}
